<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <b-button
                variant="outline-success"
                style="margin-top: -48px"
                @click="openModal('add')"
              >
                <i class="fas fa-plus"></i> Add Merchant Points Program
              </b-button>
              
              <b-button
                style="margin-left: 20px; margin-top: -48px;"
                class="btn btn-info mr-3"
                @click="addCashbackFeedback()"
              ><i class="nav-icon fas fa-comment"></i> Add Feedback</b-button> 
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Merchant Points Program Master</h3>
              </div>
              <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>   
              <div class="card-body">
                <table
                    id="cashbackMasterTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th width="8%">Program Name</th>
                        <th width="8%">Status</th>
                        <th width="5%">Created On</th>
                        <th width="5%">Action</th>
                        <th width="10%">Copy Program</th>
                      </tr>
                    </thead>
                  </table>
              </div>           
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- SM Modal Start -->
    <b-modal
      id="sm-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      @show="resetModal"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="handleOk"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
    <form ref="cashbackfrm">
    <div class="card">
      <div class="card-body row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Program Name:<span class="red">*</span></label> <br>
            <input type="text" class="form-control" v-model="CashbackDetails.cashback_program_name" :name="'point_back_program_name'"  v-validate="'required'">
            <span v-show="errors.has('point_back_program_name')" class="text-danger">The point back program name field is required</span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="reward_wheel">
                States:
                <span v-if="(CashbackDetails.status_id == activeStatusID)" class="red">*</span>
            </label>
            <multiselect
              name="state"
              v-model="selectedStates"
              :options="states"
              :custom-label="stateCustomName"
              group-values="data"
              group-label="label"
              :group-select="true"
              ref="state"
              :multiple="true"
              placeholder="Select state"
              track-by="state"
              v-validate="CashbackDetails.status_id != activeStatusID ? '' : 'required'"
            >
            </multiselect>
            <span v-show="errors.has('state')" class="text-danger">{{ errors.first('state') }}</span>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Participating Store(s): <span v-if="(CashbackDetails.status_id == activeStatusID)" class="red">*</span><sup style="margin-left: 0px;top: -7px;"><a style="color: #000;" href="javascript:void(0)" v-b-tooltip.hover title="When consumers purchase from selected stores, they can earn Merchant Points.">
                <svg fill="#000000" width="15px" viewBox="-1 0 19 19" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><path d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"/></svg>
            </a></sup></label> <br>
            <multiselect
                id="store"
                name="store"
                v-model="selectedStore"
                :options="storeList"
                :multiple="true"
                track-by="id"
                label="retailer" 
                :custom-label="customStoreSelectLabel"
                placeholder="Select Store"
                selectLabel
                deselectLabel
                :close-on-select="false"
                :clear-on-select="false"
                group-values="data"
                group-label="label"
                :group-select="true"
                v-validate="CashbackDetails.status_id != activeStatusID ? '' : 'required'"
              ></multiselect>
            <span v-show="errors.has('store')" class="text-danger">{{ errors.first('store') }}</span>
          </div>
        </div>
        
        <div class="col-md-12">
          <label class="switch"><input type="checkbox" id="use_as_generic_points" name="use_as_generic_points" v-model="CashbackDetails.status_id" :true-value="activeStatusID" :false-value="inactiveStatusID" class="enable-employee-login"><span class="slider round"></span></label>
            <label for="use_as_generic_points" class="ml-1">Status: <span v-if="CashbackDetails.status_id == activeStatusID">Active</span><span v-else>Inactive</span></label>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body row">
        <div class="col-md-12">
          <label class="switch"><input type="checkbox" id="add_new_store" name="add_new_store" v-model="CashbackDetails.add_new_store" class="enable-employee-login"><span class="slider round"></span></label>
          <label for="add_new_store" class="ml-1">Default Program For New Retail Locations</label>
        </div>
        <div class="col-md-12">
          <label class="switch"><input type="checkbox" id="use_as_generic_points" name="use_as_generic_points" v-model="CashbackDetails.use_as_generic_points" true-value="1" false-value="0" class="enable-employee-login"><span class="slider round"></span></label> <label for="use_as_generic_points">Corporate Parent Specific Points
            <sup style="margin-left: 0px;top: -7px;"><a style="color: #000;" href="javascript:void(0)" v-b-tooltip.hover title="If this toggle switch is off, points can be used at any store in CanPay. If it is on, points can only be used at all stores within the Corporate Parent.">
                <svg fill="#000000" width="15px" viewBox="-1 0 19 19" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><path d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"/></svg>
            </a></sup>
          </label>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="canpay_first_transaction_point_back_percentage">Consumer's First-Ever Transaction Using CanPay:
          </label>
            <div class="position-relative">
              <input type="text" v-model="CashbackDetails.canpay_first_transaction_cashback_percentage" :name="'canpay_first_transaction_point_back_percentage'" class="form-control pr-4" placeholder="Percentage" v-validate="'max_value:100'" v-numeric @input="formatPercentage('canpay_first_transaction_cashback_percentage')">
              <span class="input-icon">%</span>
            </div>
            <span v-show="errors.has('canpay_first_transaction_point_back_percentage')" class="text-danger">{{ errors.first('canpay_first_transaction_point_back_percentage') }}</span>
            <span v-show="tr_errors['canpay_first_transaction_cashback_percentage_error']" class="text-danger">{{ tr_errors['canpay_first_transaction_cashback_percentage_error'] }}</span>
          </div>
        </div>
      </div>
    </div>
    
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <input type="checkbox" id="all_transaction" v-model="CashbackDetails.all_transaction" />
            <label for="all_transaction" class="ml-1">All Transaction(s)</label>
          </div>
          <div class="col-md-12" v-if="CashbackDetails.all_transaction == 1">
            <div class="form-group">
              <label for="point_back_percentage">Merchant Points Program Percentage For All Transaction:</label>
              <div class="position-relative">
                <input type="text" v-model="CashbackDetails.all_transaction_cashback_percentage" :name="'point_back_percentage'" class="form-control pr-4" placeholder="Percentage" v-validate="'required|max_value:100'" v-numeric @input="formatPercentage('all_transaction_cashback_percentage')">
                <span class="input-icon">%</span>
              </div>
              <span v-show="tr_errors['all_transaction_cashback_percentage_error']" class="text-danger">{{ tr_errors['all_transaction_cashback_percentage_error'] }}</span>
            </div>
          </div>
        </div>
        <div v-if="CashbackDetails.all_transaction == 0">
          <label for="">Transaction Count In Store:</label>
          <div class="row" v-for="(field, index) in CashbackDetails.transaction_data" :key="index">
              <div class="col-md-3">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Transaction Start" :name="'transaction_start' + index" v-model="field.transaction_start" v-integer>
                  <span v-show="tr_errors['tr_start' + index]" class="text-danger">{{ tr_errors['tr_start' + index] }}</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Transaction End" :name="'transaction_end' + index" v-model="field.transaction_end" v-integer>
                  <span v-show="tr_errors['tr_end' + index]" class="text-danger">{{ tr_errors['tr_end' + index] }}</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <div class="position-relative">
                    <input type="text" v-model="field.cashback_percentage" :name="'point_back_percentage'+index" class="form-control pr-4" placeholder="Percentage" v-validate="'required|max_value:100'" v-numeric @input="formatPercentage(index, 'cashback_percentage')">
                    <span class="input-icon">%</span>
                  </div>
                  <span v-show="tr_errors['tr_percentage' + index]" class="text-danger">{{ tr_errors['tr_percentage' + index] }}</span>
                </div>
              </div>

              <div class="col-md-2">
              <div class="form-group">
              <a @click="addField" v-if="index == 0" class="btn btn-success white">Add New</a>
              <a @click="deleteField(index)" class="custom-edit-btn" title="Delete Row" v-else><i class="nav-icon fas fa-trash"></i></a>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    </form>
    </b-modal>
    <!-- SM Modal End -->

    <!-- Terms and Conditions modal start -->
    <b-modal ref="confirmation-modal" hide-footer hide-header id="confirmation-modal" no-close-on-backdrop>
        <div class="color">
        <div class="col-12 text-center">
            <svg fill="none" width="100px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.87 10.5046L10.8204 12.4504L9.76045 13.511L6 9.75456L9.76045 5.99805L10.8204 7.05871L8.87 9.00456H18V18H11.9532V16.5H16.5V10.5046H8.87Z" fill="#149240"/>
            </svg>
        </div>
        <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
            <label class="text-justify text-secondary h4">
                Terms and Conditions
            </label>
            <br />
            <label class="text-justify text-secondary text-dark">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consequat arcu in ultricies placerat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec non auctor mauris. Curabitur pretium ullamcorper tortor, id fermentum nisi sodales quis. Nunc accumsan bibendum eros, eu vulputate ipsum rhoncus vitae. Aenean luctus suscipit auctor. Nunc congue feugiat feugiat. Etiam eu nulla arcu. Proin sit amet sollicitudin sem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.

                Curabitur vel justo vel nisi finibus sollicitudin non ut enim. Maecenas vel enim tortor. Donec sit amet magna ac ipsum venenatis dignissim. Morbi ultricies consectetur lorem, sit amet efficitur diam maximus quis. Quisque facilisis commodo dui vitae finibus. Curabitur facilisis magna sit amet metus eleifend dignissim. Phasellus egestas justo nec nisl ornare posuere nec eget lacus. Pellentesque hendrerit, odio in efficitur volutpat, nisi leo sagittis odio, et ornare enim magna et ligula. Nullam hendrerit ante est, id mollis odio mollis a. Sed fermentum nisl non turpis viverra, id dignissim sapien ultrices. Nunc ultrices mauris vitae urna tristique, eget convallis nibh dignissim. Sed elementum sem eget fringilla porta. Proin at sapien blandit, aliquam ligula at, laoreet purus. Vivamus consectetur nunc vel urna interdum semper. Nam blandit magna aliquet tristique placerat.
            </label>
            </div>
            <div class="row">
            <div class="col-12 text-center">
                <button
                @click="declineTermsAndConditions()"
                class="btn btn-secondary btn-md center-block mr-2"
                >
                <label class="forgetpassword-ok-label my-0">Decline</label>
                </button>
                <button
                @click="checkMerchantCashbackAcceptance('accept')"
                class="btn btn-success btn-md center-block ml-2"
                >
                <label class="forgetpassword-ok-label my-0">Accept</label>
                </button>
            </div>
            </div>
        </div>
        </div>
    </b-modal>
    <!-- Terms and Conditions modal end -->

    <!-- Show feedback Modal start-->
    <b-modal
      id="add-feedback-modal"
      ref="add-feedback-modal"
      :header-text-variant="headerTextVariant"
      title="Add Feedback"
      ok-title="Save"
      ok-variant="success"
      @ok="saveFeedback"
      cancel-variant="outline-secondary"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <form ref="cashbackfeedbackfrm">
        
        <div class="card">
          <div class="card-body row">
            <div class="col-12">
              <div class="form-group">
                <label for="">Name:<span class="red">*</span></label>
                <input type="text" class="form-control" v-model="user_name" :name="'user_name'"  v-validate="'required'">
                <span v-show="errors.has('user_name')" class="text-danger">The name field is required</span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="">Email address:<span class="red">*</span></label>
                <input type="text" class="form-control" v-model="user_email" :name="'email'"  v-validate="'required|email'">
                <span v-show="errors.has('email')" class="text-danger">{{  errors.first("email") }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="">Phone Number:<span class="red">*</span></label>
                <input type="text" class="form-control" v-model="user_phone" :name="'phone'"  v-validate="'required|numeric'">
                <span v-show="errors.has('phone')" class="text-danger">{{  errors.first("phone") }}</span>
              </div>
            </div>
            
            <div class="col-md-12">
                <div class="form-group">
                <label for="merchant">
                    Select Merchant Points Program:<span class="red">*</span>
                </label>
                <multiselect
                    id="cashback_program"
                    name="cashback_program"
                    v-model="selectedCashbackProgram"
                    placeholder="Select Merchant Points Program"
                    label="cashback_program_name"
                    :options="cashbackProgramList"
                    :internal-search="true"
                    v-validate="'required'"
                ></multiselect>
                
                <span v-show="errors.has('cashback_program')" class="text-danger">{{ errors.first('cashback_program') }}</span>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                <label for="feedback">
                  Provide Feedback:<span class="red">*</span>
                </label>
                <b-form-textarea
                  id="feedback"
                  v-model="feedback"
                  placeholder=""
                  rows="6"
                  max-rows="6"
                  v-validate="'required'"
                  :name="'feedback'"
                ></b-form-textarea>
                <span v-show="errors.has('feedback')" class="text-danger">{{ errors.first('feedback') }}</span>
                </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- Show feedback Modal end -->
  </div>
</template>
<script>
import api from "@/api/cashback.js";
import apiuser from "@/api/user.js";
import moment from "moment";
import { HourGlass } from "vue-loading-spinner";
import Constants from "@/common/constant.js";

export default {
  data() {
    return {
      userDetails: JSON.parse(localStorage.getItem("store_user")),
      modalTitle: "",
      headerTextVariant: "light",
      add: true,
      storeList: [],
      selectedStore: "",
      statusList:[],
      activeStatusID:"",
      inactiveStatusID:"",
      showReloadBtn: false,
      allCashbackModel:{},
      numberOptions:[],
      loading: false,
      CashbackDetails:{
        cashback_program_name:"",
        tax:"",
        status_id:"",
        use_as_generic_points:1,
        all_transaction:0,
        add_new_store:0,
        canpay_first_transaction_cashback_percentage:"",
        all_transaction_cashback_percentage:"",
        transaction_data: [{ transaction_start: '', transaction_end: '', cashback_percentage: '' }],
      },
      selectedNumbers: [], // New array to store selected phone numbers
      tr_errors: [],
      states: [],
      selectedStates: [],
      user_name: '',
      user_email: '',
      user_phone: '',
      feedback: '',
      selectedCashbackProgram: null,
      cashbackProgramList: [],
      type: ''
    };
  },
  components: {
    HourGlass,
  },
  mounted() {
    var self = this;
    setTimeout(function () {
      self.loadDT();
    }, 1000);
    self.getStatus();
    self.getMerchantStates();
    self.generateNumberOptions();
    self.editCashbackDetails();
    self.copyCashbackDetails();
    self.statusChangeCashBack();
    self.getAllCashbackPrograms();
    document.title = "CanPay - Merchant Points Program";
  },
  beforeDestroy(){
    $(document).off('click', '.editCashbackDetails');
    $(document).off('click', '.copyCashbackDetails');
    $(document).off('click', '.statusChangeCashBack');
  },
  watch: {
    CashbackDetails: {
      handler() {
        this.validateFields();
      },
      deep: true
    },
    selectedStates: {
      handler() {
        var self = this;
        if (self.selectedStates.length > 0) {
          this.getAuthorizedStores();
        }
      },
      deep: true
    }
  },
  methods: {
    getAllCashbackPrograms() {
      var self = this;
      api
        .getAllCashbackPrograms()
        .then(function (response) {
          if (response.code == 200) {
            self.cashbackProgramList = response.data.concat([{'cashback_program_name': 'Other', 'id' : 'other'}]);
          }else {
            error(response.message);
          }
        })
        .catch(function (error) {
        });
    },
    saveFeedback(bvModalEvt){
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      
      self.$validator.validateAll().then(result => {
        if (result) {
          var request = {
            feedback: self.feedback,
            name: self.user_name,
            email: self.user_email,
            phone: self.user_phone,
            cashback_program_id: self.selectedCashbackProgram.id
          };
          api
          .saveCashbackFeedback(request)
          .then(response => {
            if(response.code == 200){
              success(response.message);
              self.hideModal('add-feedback-modal');
              self.feedback = '';
            }
          })
          .catch(err => {
              if(err.response.data.message){
                  error(err.response.data.message);
              }else{
                  error(err);
              }
          });
        }
      })
      .catch(err => {
          error(err);
      });
      
      
    },
    hideModal(modal){
      var self = this;
      self.$bvModal.hide(modal);
    },
    declineTermsAndConditions(){
      var self = this;
      self.hideModal('confirmation-modal')
      self.$router.push('/dashboard');
    },
    checkMerchantCashbackAcceptance(acceptance = false){
      var self = this;
      if(acceptance == 'accept'){
        var request = {
          'accepted':1,
        };
        self.hideModal('confirmation-modal')
      }
      api
      .checkMerchantCashbackAcceptance(request)
      .then(response => {
        if(acceptance == 'accept'){
          success(response.message);
        }
        if(response.data.accepted == 0){
          self.$bvModal.show('confirmation-modal');
        }
      })
      .catch(err => {
          if(err.response.data.message){
              error(err.response.data.message);
          }else{
              error(err);
          }
      });
    },
    stateCustomName (data) {
      return data.state
    },
    //get the autorized merchant list
    getMerchantStates() {
      var self = this;
      api
        .getMerchantStates()
        .then(function (response) {
          if (response.code == 200) {
            self.states = [{
                label: 'Select All States',
                data: response.data
            }]
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          error(error);
           });
    },
    //get the autorized merchant list
    getAuthorizedStores() {
      var self = this;
      if (self.type == "edit") {
        var request = {
          id: self.edit_id,
          state: self.selectedStates
        };
      } else {
        var request = {
          state: self.selectedStates
        };
      }
      api
        .getStoresForCashbackProgram(request)
        .then(function (response) {
          if (response.code == 200) {
            self.storeList = response.data;
            if (self.type != "edit") {
              self.selectedStore = self.storeList;
            } else {
              let combined = [...self.selectedStore, ...self.storeList];
              let uniqueStores = combined.reduce((acc, current) => {
                if (!acc.find(store => store.id === current.id)) {
                  acc.push(current);
                }
                return acc;
              }, []);
              self.storeList = uniqueStores;
            }
            self.storeList = [{
                label: 'Select All Stores',
                data: self.storeList
            }]
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          error(error);
           });
    },
    customStoreSelectLabel(store) {
      return store && store.retailer ? store.retailer + ' - ' + store.state : ''
    },
    addField() {
      var self = this;
      const newField = { transaction_start: '', transaction_end: '', cashback_percentage: '' };
      self.CashbackDetails.transaction_data.push(newField);
      self.$nextTick(() => {
        self.$validator.localize('en');
        self.$validator.reset();
      });
    },
    formatPercentage(field, subfield) {
      const value = this.$data.CashbackDetails[field] || this.$data.CashbackDetails.transaction_data[field][subfield];
      let formattedValue = value.replace(/[^0-9.]/g, '');

      let parts = formattedValue.split('.');
      if (parts.length > 2) {
        formattedValue = parts[0] + '.' + parts.slice(1).join('');
      }

      if (parts[1]) {
        formattedValue = parts[0] + '.' + (parts[1].substring(0, 1) || '');
      }

      if (field === 'canpay_first_transaction_cashback_percentage' || field === 'all_transaction_cashback_percentage') {
        this.$data.CashbackDetails[field] = formattedValue;
      } else {
        this.$data.CashbackDetails.transaction_data[field][subfield] = formattedValue;
      }
    },
    validateFields() {
      this.tr_errors = [];
      var first_transaction_index = '';

      let first_percentage = parseInt(this.CashbackDetails.canpay_first_transaction_cashback_percentage, 10);
      let all_transaction_percentage = parseInt(this.CashbackDetails.all_transaction_cashback_percentage, 10);
      if (this.CashbackDetails.all_transaction == 1 && isNaN(all_transaction_percentage)) {
        this.tr_errors[`all_transaction_cashback_percentage_error`] = "Percentage field is required";
      }
      if (first_percentage > 0 && this.CashbackDetails.all_transaction == 1 && all_transaction_percentage > first_percentage) {
        this.tr_errors[`all_transaction_cashback_percentage_error`] = "The percentage must be less than the Merchant Points Program percentage for Consumer's Initial Transaction Using CanPay";
        this.tr_errors[`canpay_first_transaction_cashback_percentage_error`] = 'The percentage is greater than the Merchant Points Program Percentage For All Transaction';
      }
      if (this.CashbackDetails.all_transaction == 0) {
        this.CashbackDetails.transaction_data.forEach((field, index) => {
          let cashback_percentage = parseInt(field.cashback_percentage, 10);
          let transaction_start = parseInt(field.transaction_start, 10);
          let transaction_end = parseInt(field.transaction_end, 10);
          if (isNaN(transaction_start)) {
            this.tr_errors[`tr_start${index}`] = 'Starting transaction count is required.';
          } else if (isNaN(transaction_end)) {
            this.tr_errors[`tr_end${index}`] = 'Ending transaction count is required.';
          } else if (transaction_end < transaction_start) {
            this.tr_errors[`tr_end${index}`] = 'Transaction count end cannot be earlier than transaction count start.';
          }
          if (isNaN(cashback_percentage)) {
            this.tr_errors[`tr_percentage${index}`] = 'Percentage is required.';
          }else if (cashback_percentage > 100) {
            this.tr_errors[`tr_percentage${index}`] = 'Percentage must be 100 or less';
          }else if (cashback_percentage > first_percentage && first_percentage > 0) {
            this.tr_errors[`tr_percentage${index}`] = "Percentage must be less than the Merchant Points Program percentage for Consumer's Initial Transaction Using CanPay";
            this.tr_errors[`canpay_first_transaction_cashback_percentage_error`] = "The percentage is greater than the Merchant Points Program percentage for Transaction Count In Store";
          }
          if (transaction_start <= 1 && transaction_end >= 1) {
            first_transaction_index = index;
          }
          for (let i = 0; i < index; i++) {
            let otherField = this.CashbackDetails.transaction_data[i];
            if (
              (otherField.transaction_start <= transaction_start && otherField.transaction_end >= transaction_start) ||
              (otherField.transaction_start <= transaction_end && otherField.transaction_end >= transaction_end) ||
              (transaction_start <= otherField.transaction_start && transaction_end >= otherField.transaction_start) ||
              (transaction_start <= otherField.transaction_end && transaction_end >= otherField.transaction_end)
            ) 
              this.tr_errors[`tr_start${index}`] = 'Transaction range overlaps with another entry.';
          }
        });
      }

    },
    deleteField(index) {
      var self = this;
      if (confirm("Are you sure you want to delete this row?")) {
        self.CashbackDetails.transaction_data.splice(index, 1);
      }
    },
    save(){
      var self = this;
      self.$validator.validateAll().then(result => {
          if (result && Object.keys(self.tr_errors).length === 0) {
            self.saveCashbackprogramData();
          }
      })
      .catch(err => {
          error(err);
      });
    },
    saveCashbackprogramData() {
      var self = this;
      // Format store id
      var storeId = [];
      if(self.storeList.length > 0)
      {
        self.selectedStore.filter((item) => {
        storeId.push(item.id);
      });
      }
      
        var request = {
          edit_id: self.CashbackDetails.id,
          cashback_program_name: self.CashbackDetails.cashback_program_name,
          tax: self.CashbackDetails.tax,
          status_id: self.CashbackDetails.status_id,
          all_transaction: self.CashbackDetails.all_transaction,
          add_new_store: self.CashbackDetails.add_new_store ? 1 : 0,
          all_transaction_percentage: self.CashbackDetails.all_transaction_cashback_percentage,
          canpay_first_transaction_cashback_percentage: self.CashbackDetails.canpay_first_transaction_cashback_percentage,
          use_as_generic_points: self.CashbackDetails.use_as_generic_points == 0 ? 1 : 0,
          store_ids: storeId,
          transaction_data: self.CashbackDetails.transaction_data
        };
        
        api
        .addeditCashbackProgram(request)
        .then(response => {
          if(response.code == 200){
            success(response.message);
            self.hideModal('sm-modal');
            self.loadDT();
            // Reset the fields
            self.CashbackDetails = [];
            self.CashbackDetails.transaction_data = [{ transaction_start: '', transaction_end: '', cashback_percentage: ''}];
            self.selectedStore = "";
            self.$validator.reset();
          }
        })
        .catch(err => {
            if(err.response.data.message){
                error(err.response.data.message);
            }else{
                error(err);
            }
        });
    },
    reloadDatatable() {
      var self = this;
      self.loadDT();
    },
    loadDT: function () {
      var self = this;      
      $("#cashbackMasterTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [3, 4] },
          { className: "dt-left break-all", targets: [0, 1, 2] },
          { className: "dt-center", targets: [3] },
          { className: "dt-center", targets: [4] },
        ],
        order: [[1, "asc"], [2, "desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Cashback Program Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: "Bearer "+localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_REWARD_WHEEL_API_URL + "/api/merchantadmin/getcashbackprogramlist",
          type: "POST",
          data: { _token: "{{csrf_token()}}" },
          dataType: "json",
          dataSrc: function (result) {
            self.showReloadBtn = false;
            self.allCashbackModel = result.data;
            return self.allCashbackModel;
          },
          error: function (request) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            }else{
              error(Constants.datatable_error);
              $('#cashbackMasterTable_processing').hide();
              self.showReloadBtn = true;
            }
          },
        },
        columns: [
          { data: "cashback_program_name"},
          
          {
            render: function (data, type, full, meta) {
              var checked = (full.status_code == 204) ? 'checked' : '';
              return (
                    '<label class="switch"><input class="statusChangeCashBack allow-merchant-fees-report" ' + checked + ' type="checkbox" data-user-id="' + full.edit_id +'" true-value="' + self.activeStatusID +'" :false-value="' + self.inactiveStatusID +'"><span class="slider round"></span></label>'
                  );
            },
          },  
          { data: "created_at" },
          {
            render: function (data, type, full, meta) {
              return (
                '<b-button data-user-id="' +
                full.edit_id +
                '" class="editCashbackDetails custom-edit-btn" title="Edit Merchant Points Program Details" variant="outline-success"><i class="nav-icon fas fa-edit"></i></b-button><b-button data-user-id="</b-button>'
              );
            },
          },
          {
            render: function (data, type, full, meta) {
              return (
                '<b-button data-user-id="' +
                full.edit_id +
                '" class="copyCashbackDetails custom-edit-btn" title="Copy Merchant Points Program" variant="outline-success"><i class="nav-icon fas fa-clone"></i></b-button>'
              );
            },
          }
        ],
      });

      $("#cashbackMasterTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });

      //Search in the table only after 3 characters are typed
      // Call datatables, and return the API to the variable for use in our code
      // Binds datatables to all elements with a class of datatable
      var dtable = $("#cashbackMasterTable").dataTable().api();

      // Grab the datatables input box and alter how it is bound to events
      $(".dataTables_filter input")
      .unbind() // Unbind previous default bindings
      .bind("input", function(e) { // Bind our desired behavior
          // If the length is 3 or more characters, or the user pressed ENTER, search
          if(this.value.length >= 3 || e.keyCode == 13) {
              // Call the API search function
              dtable.search(this.value).draw();
          }
          // Ensure we clear the search if they backspace far enough
          if(this.value == "") {
              dtable.search("").draw();
          }
          return;
      });
    },
    getStatus() {
    var self = this;
    api
      .getStatus()
      .then((response) => {
        if ((response.code = 200)) {
          response.data.forEach((field, index) => {
            if (field.code == '204') {
              self.activeStatusID = field.id;
            } else if (field.code == '205') {
              self.inactiveStatusID = field.id;
            }
          });
        } else {
          error(response.message);
        }
      })
      .catch((err) => {
        error(err);
      });
    },
    showModal(modal){
      var self = this;
      self.$bvModal.show(modal);
    },
    openModal(type) {
      var self = this;
      self.selectedStore = [];
      self.selectedStates = [];
      self.type = type;
      if (type == "edit") {
        self.modalTitle = "Edit Merchant Points Program";
        self.add = false;
        self.$bvModal.show("sm-modal");
      } else if(type == "copy") {
        self.modalTitle = "Copy Merchant Points Program";
        self.add = false;
        self.$bvModal.show("sm-modal");
      } else {
        self.add = true;
        self.modalTitle = "Add Merchant Points Program";
        // Reset Form Fields
        self.CashbackDetails = {
          cashback_program_name:"",
          tax:"",
          status_id: self.activeStatusID,
          all_transaction:0,
          add_new_store:0,
          use_as_generic_points:1,
          canpay_first_transaction_cashback_percentage:"",
          all_transaction_cashback_percentage:"",
          transaction_data : [{ transaction_start: '', transaction_end: '', cashback_percentage: '' }],
          state : []}
        self.$bvModal.show("sm-modal");
      }
    },
    resetModal() {
      var self = this;
      self.AccountantDetails = {};
      self.store_ids = null;
    },
    handleOk(bvModalEvt) {
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      self.save();
    },
    generateNumberOptions() {
      var self = this;
      const availableOptions = [];
        for (let i = 1; i <= 50; i++) {
          if (!this.selectedNumbers.includes(i)) { // Exclude selected phone numbers
            availableOptions.push(i);
          }
        }
        this.numberOptions = availableOptions;
    },
    getAvailableNumberOptions(index) {
      var self = this;
      return self.numberOptions.filter(option => !self.CashbackDetails.transaction_data.slice(0, index).some(transaction_data => transaction_data.transaction_number.includes(option)));
    },
    sortSelectedNumbers(index) {
      var self = this;
      self.CashbackDetails.transaction_data[index].transaction_number.sort((a, b) => a - b);
    },
    updateSelectedNumbers(index) {
      var self = this;
      const selectedNumbers = [];
      for (const transaction of self.CashbackDetails.transaction_data) {
        selectedNumbers.push(...transaction_data.transaction_number);
      }
      self.selectedNumbers = selectedNumbers;
      self.generateNumberOptions();
    },
    addCashbackFeedback(){
      var self = this;
      self.selectedCashbackProgram = null;
      if(self.userDetails.role_name  == Constants.role_corporate_parent){
        self.user_name = self.userDetails.contact_person_first_name + " " + self.userDetails.contact_person_last_name;
        self.user_email = self.userDetails.contact_person_email;
        self.user_phone = self.userDetails.contact_person_phone;
      } else {
        if (self.userDetails.middle_name) {
          self.user_name =
          self.userDetails.first_name +
          " " +
          self.userDetails.middle_name +
          " " +
          self.userDetails.last_name;
        } else {
          self.user_name = self.userDetails.first_name + " " + self.userDetails.last_name;
        }
        self.user_email = self.userDetails.email;
        self.user_phone = self.userDetails.phone;
      }
      self.showModal("add-feedback-modal");
    },
    statusChangeCashBack(){
      var self = this;
      $(document).on("click", ".statusChangeCashBack", function (e) {
        self.edit_id = $(e.currentTarget).attr("data-user-id");
        // Determine if the checkbox is checked or not
        var isChecked = $(e.currentTarget).is(":checked");
        // Get the status value based on whether the checkbox is checked
        var statusValue = isChecked ? self.activeStatusID : self.inactiveStatusID;
        self.statusChangeCashBackProgram(statusValue, e.currentTarget, isChecked);
      })
    },
    statusChangeCashBackProgram(status_id, checkboxElement, currectCheckedState){
      var self = this;
      var request = {
          cashback_program_id: self.edit_id,
          status_id: status_id
      };
      api
      .statusChangeCashBackProgram(request)
      .then((response) => {
        if ((response.code = 200)) {
          success(response.message);
        } else {
          $(checkboxElement).prop("checked", !currectCheckedState);
          error(response.message);
        }
      })
      .catch((err) => {
        $(checkboxElement).prop("checked", !currectCheckedState);
        if(err.response.data.message){
            error(err.response.data.message);
        }else{
            error(err);
        }
      });
    },
    editCashbackDetails(){
      var self = this;
      $(document).on("click", ".editCashbackDetails", function (e) {
        self.edit_id = $(e.currentTarget).attr("data-user-id");
        self.openModal('edit');
        self.getCashbackProgramData();
      });
    },
    getCashbackProgramData(){
      var self = this;
      var request = {
          cashback_program_id: self.edit_id
        };
        api
        .getCashbackProgramData(request)
        .then((response) => {
          if ((response.code = 200)) {
            self.selectedStore = response.data.store_ids;
            self.CashbackDetails = response.data;
            self.CashbackDetails.use_as_generic_points = response.data.use_as_generic_points == 0 ? 1 : 0;
            self.CashbackDetails.transaction_data = response.data.transaction_data.length == 0 ? [{ transaction_start: '', transaction_end: '', cashback_percentage: '' }] : response.data.transaction_data;
            if (self.type == 'copy'){
              self.storeList = [];
              self.selectedStore = [];
              delete self.CashbackDetails.id;
              self.CashbackDetails.add_new_store = 0;
              self.CashbackDetails.cashback_program_name = self.CashbackDetails.cashback_program_name + ' Copy';
            } else {
              self.setStates(response.data.store_ids);
            }
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err);
        });
    },
    setStates(cashbackStores){
        this.selectedStates = []
        cashbackStores.forEach(cashbackStore => {
            this.selectedStates.push(this.states[0].data.find(state => state.state == cashbackStore.state))
        });

        // remove duplicates from state array
        this.selectedStates = this.selectedStates.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.state === value.state
            ))
        )
    },
    copyCashbackDetails(){
      var self = this;
      $(document).on("click", ".copyCashbackDetails", function (e) {
        self.edit_id = $(e.currentTarget).attr("data-user-id");
        self.openModal('copy');
        self.getCashbackProgramData();
      });
    }
  },
};
</script>
<style>
.form-group {
  position: relative;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent the icon from blocking input interactions */
  color: #888; /* Customize color as needed */
  font-size: 16px; /* Adjust font size as needed */
}

.form-control.pr-4 {
  padding-right: 30px; /* Make space for the icon */
}

</style>