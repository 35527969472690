<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Settlement/Fees Report</h3>
              </div>
              <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <multiselect
                        id="store"
                        name="store"
                        v-model="selectedStore"
                        placeholder="Select Store"
                        label="retailer"
                        track-by="id"
                        :options="storeList"
                        :multiple="false"
                        :searchable="true"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input
                        class="start-date form-control"
                        placeholder="Start Date"
                        id="start-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input
                        class="end-date form-control"
                        placeholder="End Date"
                        id="end-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="switch"
                        ><input
                          class="enable-employee-login"
                          type="checkbox"
                          v-model="singular_debit" /><span
                          class="slider round"
                        ></span
                      ></label>
                      Show Singular Debit Column
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="generateReport(false)"
                >
                  Generate
                </button>
                <button
                  type="button"
                  @click="generateReport(true)"
                  class="btn btn-danger ml-10"
                >
                  Generate & Export<i
                    class="fa fa-download ml-10"
                    aria-hidden="true"
                  ></i>
                </button>
                <button
                  type="button"
                  @click="exportAllStores()"
                  class="btn btn-danger ml-10 generateBtn"
                >
                  Export All Stores<i
                    class="fa fa-download ml-10"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <b-table-simple
                      responsive
                      show-empty
                      bordered
                      sticky-header="800px"
                    >
                      <b-thead head-variant="light" style="position: sticky; top:0;">
                        <b-tr>
                          <b-th colspan="1" class="text-center th-white">
                            <span v-if="selectedStore != []"
                              >Store Name:
                              {{ selectedStore.retailer }}</span
                            >
                          </b-th>
                          <b-th colspan="9" class="text-center b-top b-right"
                            >Canpay Sales Activity</b-th
                          >
                          <b-th colspan="2" class="text-center b-top b-right"
                            >Merchant Rewards</b-th
                          >
                        </b-tr>
                        <b-tr>
                          <b-th class="text-center">Sales Date</b-th>

                          <b-th class="text-center">Sales Volume</b-th>
                          <b-th class="text-center">Rate</b-th>
                          <b-th class="text-center">Vol Fee</b-th>
                          <b-th class="text-center"># of Trans</b-th>
                          <b-th class="text-center">Rate</b-th>
                          <b-th class="text-center">Trans Fees</b-th>
                          <b-th class="text-center">Sub Total Fees</b-th>
                          <b-th class="text-center">Free Processing Reimbursement</b-th>
                          <b-th class="text-center">Final Total Fees</b-th>

                          <b-th class="text-center">Points Program Contribution</b-th>
                          <b-th class="text-center">Reward Points Contribution</b-th>
                          <b-th class="text-center" v-if="singular_debit">Total Debit</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody v-for="(row, index) in report" :key="index">
                        <b-tr :class="row.sales_date === 'Totals' ? 'report-total-cls' : ''">
                          <b-td class="text-left" :class="{'bold-text': row.sales_date === 'Totals'}">
                            {{ row.sales_date }}
                          </b-td>
                          <b-td class="text-right bold-text">
                            {{ row.v2_retail_volume }}
                          </b-td>
                          <b-td class="text-right" :class="{'bold-text': row.sales_date === 'Totals'}">
                            {{ row.v2_retail_vol_rate }}
                          </b-td>
                          <b-td class="text-right" :class="{'bold-text': row.sales_date === 'Totals'}">
                            {{ row.v2_retail_vol_fee }}
                          </b-td>
                          <b-td class="text-center" :class="{'bold-text': row.sales_date === 'Totals'}">
                            {{ row.v2_retail_no_of_trans }}
                          </b-td>
                          <b-td class="text-right" :class="{'bold-text': row.sales_date === 'Totals'}">
                            {{ row.v2_retail_fee_rate }}
                          </b-td>
                          <b-td class="text-right" :class="{'bold-text': row.sales_date === 'Totals'}">
                            {{ row.v2_retail_trans_fee }}
                          </b-td>
                          <b-td class="text-right" :class="{'bold-text': row.sales_date === 'Totals'}">
                            {{ row.v2_retail_sub_total_fees }}
                          </b-td>
                          <b-td class="text-right text-danger" :class="{'bold-text': row.sales_date === 'Totals'}">
                            {{ row.v2_retail_sub_total_fees_waived }}
                          </b-td>
                          <b-td class="text-right bold-text">
                            {{ row.v2_final_fees }}
                          </b-td>
                          <b-td class="text-right bold-text">
                            {{ row.v2_retail_cashback_volume }}
                          </b-td>
                          <b-td class="text-right bold-text" v-if="row.sales_date === 'Totals' || row.v2_merchant_reward_volume === '$0.00'">
                            {{ row.v2_merchant_reward_volume }}
                          </b-td>
                          <b-td class="text-right bold-text" v-else>
                            <a class="cursor-pointer" @click="viewMerchantRewarsList(row.sales_date)" title="View Reward Points Details">{{ row.v2_merchant_reward_volume }}</a>
                          </b-td>
                          <b-td class="text-right bold-text" v-if="singular_debit">
                            {{ row.v2_retail_total_debit }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-modal
          id="view-merchant-rewards-modal"
          ref="modal"
          :header-text-variant="headerTextVariant"
          title="Reward Points Details"
          ok-variant="success"
          :no-close-on-esc="true"
          :no-close-on-backdrop="true"
          :hide-footer="true"
          >
          <div class="row">
            
            <div class="col-md-12">
              <table class="table table-striped table-valign-middle td-pad-15">
                <thead>
                  <tr>
                    <th class="text-center">Transaction Number</th>
                    <th class="text-center">Transaction Date</th>
                    <th class="text-center">Reward Amount</th>
                    <th class="text-center">Date Of Wheel Spin</th>
                  </tr>
                </thead>
                <tbody v-for="(row, index) in rewardDetails" :key="index">
                  <tr>
                    <td class="text-center">{{row.transaction_number}}</td>
                    <td class="text-center">{{row.local_transaction_date}}</td>
                    <td class="text-center">{{row.reward_amount}}</td>
                    <td class="text-center">{{row.wheel_spin_date}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-modal>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/api/reports.js";
import apiuser from "@/api/user.js";
import moment from "moment";
import { saveAs } from "file-saver";
import { HourGlass } from "vue-loading-spinner";
export default {
  data() {
    return {
      headerTextVariant:"light",
      report: [],
      fields: [],
      v1_label: "Hide",
      web_label: "Hide",
      retail_label: "Hide",
      all_label: "Hide",
      toggle_v1: 1,
      toggle_web: 1,
      toggle_retail: 1,
      toggle_all: 1,
      loading: false,
      showOldSalesReport: false,
      singular_debit: false,
      storeList: [],
      rewardDetails: [],
      selectedStore: "",
      reportGeneratedStore: "",
    };
  },
  components: {
    HourGlass,
  },
  watch: {
    toggle_v1: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.v1_label = "Show";
      } else {
        self.v1_label = "Hide";
      }
    },
    toggle_web: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.web_label = "Show";
      } else {
        self.web_label = "Hide";
      }
    },
    toggle_retail: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.retail_label = "Show";
      } else {
        self.retail_label = "Hide";
      }
    },
    toggle_all: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.all_label = "Show";
      } else {
        self.all_label = "Hide";
      }
    },
  },
  mounted() {
    this.getAuthorizedStores();
    $("#start-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
      startDate: new Date('2021-07-01'),
    });
    $("#end-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    });
    $("#start-date , #end-date").datepicker("setDate", "-1d");

    $("#checkall").change(function () {
      $(".cb-element").prop("checked", this.checked);
    });

    $(".cb-element").change(function () {
      if ($(".cb-element:checked").length == $(".cb-element").length) {
        $("#checkall").prop("checked", true);
      } else {
        $("#checkall").prop("checked", false);
      }
    });
  },
  methods: {
    //get the autorized merchant list
    getAuthorizedStores() {
      var self = this;
      apiuser
        .getStores()
        .then(function (response) {
          if (response.code == 200) {
            self.storeList = response.data;
            } else {
            error(response.message);
            }
        })
        .catch(function (error) {
          error(error);
           });
    },
    // API call to generate the merchant location transaction report
    generateReport(reportExport) {
      var self = this;
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("Start date cannot be from future.");
        return false;
      }
      if (
        moment($("#end-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("End date cannot be from future.");
        return false;
      }
      self.reportGeneratedStore = self.selectedStore;
      self.report = [];
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
        store_id: self.selectedStore.id,
        singular_debit: self.singular_debit,
      };
      if(request.from_date > request.to_date){
        error("To Date cannot be greater than From date");
        return false;
      }
      self.loading = true;
      api
        .generateV1SettlementReport(request)
        .then(function (response) {
          if (response.code == 200) {
            self.report = response.data;
            if (reportExport) {
              self.exportReport();
            } else {
              self.loading = false;
            }
          } else {
            error(response.message);
            self.loading = false;
          }
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },

    // exports the report
    exportReport() {
      var self = this;
      self.loading = true;
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
        report: self.report,
        store_name: self.selectedStore.retailer,
        singular_debit: self.singular_debit,
      };
      api
        .exportv1SettlementReport(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") + "_CanPay_Settlement_Fees_Report.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
    // This is wrapper API function , it is called for blob type response as for blob type response, the token is not refreshed automatically
    exportAllStores(){
      var self = this;
        api
        .generateRefreshToken()
        .then(function (response) {
          if (response.code == 200) {
            self.exportReportAllStores();
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          // error(error);
        });
    },
    //Export Report for all the Stores
    exportReportAllStores(){
      var self = this;
      var start_date = moment($("#start-date").val()).format("YYYY-MM-DD");
      var end_date = moment($("#end-date").val()).format("YYYY-MM-DD");
      var current_date = moment().format("YYYY-MM-DD");
      
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("Start date cannot be from future.");
        return false;
      }
      if (
        moment($("#end-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("End date cannot be from future.");
        return false;
      }
      self.loading = true;
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
        singular_debit: self.singular_debit,
      };
      api
        .exportv1SettlementReportAllStores(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") + "_CanPay_Settlement_Fees_Report_All_Stores.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
    //View all merchant rewards
    viewMerchantRewarsList(sales_date){
      var self = this;
      self.loading = true;
      var request = {
        store_id: self.reportGeneratedStore.id,
        sales_date: moment(sales_date).format("YYYY-MM-DD"),
      };
      api
        .viewMerchantRewarsList(request)
        .then(function (response) {
          if (response.code == 200) {
            self.rewardDetails = response.data;
            self.$bvModal.show('view-merchant-rewards-modal');
            self.loading = false;
          } else {
            error(response.message);
            self.loading = false;
          }
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    }
  },
};
</script>
<style>
.bold-text {
  font-weight: bold;
}  
#view-merchant-rewards-modal___BV_modal_content_{
  width: 900px !important;
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>